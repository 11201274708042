import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./../FloatingAnnotationToolbar.css";
import classes from "classnames";
import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import { updateHighlightStyle } from "../../../actions/annotations";
import { selectActiveHighlightAnnotation } from "../../../selectors/annotations";
import { selectDefaultStyle } from "../../../selectors";
import { useSelectI18nStringById } from "../../../../util/custom-hooks";
import { Mark } from "@churchofjesuschrist/glo-highlight";
import { ArrowBack } from "@churchofjesuschrist/eden-icons";
import Divider from "./Divider";

const ArrowBackButton = fatIconButtonFactory(ArrowBack);

const highlightColorStrings = {
    red: "highlightColorRed",
    orange: "highlightColorOrange",
    yellow: "highlightColorYellow",
    green: "highlightColorGreen",
    blue: "highlightColorBlue",
    dark_blue: "highlightColorDarkBlue",
    purple: "highlightColorPurple",
    pink: "highlightColorPink",
    brown: "highlightColorBrown",
    gray: "highlightColorGray",
};

export const MarkOption = ({
    className,
    clear,
    color,
    onClick,
    title,
    underline,
    ...props
}) => (
    <button
        className={styles.markOption}
        onClick={onClick}
        title={title}
        {...props}
    >
        <Mark
            className={className}
            clear={clear}
            color={color}
            underline={underline}
        >
            A
        </Mark>
    </button>
);

export const HighlightMenu = ({
    annotation,
    changeView,
    defaultStyle,
    selectI18nStringById,
    ...props
}) => {
    const [style, setStyle] = useState(defaultStyle);

    useEffect(() => {
        if (annotation) {
            const { clear, color, underline } = annotation?.highlights[0] || {};
            setStyle({ clear, color, underline });
        }
    }, [annotation]);

    const updateHighlightStyle = (newStyle) => {
        setStyle({ ...style, ...newStyle });
        props.updateHighlightStyle({ ...style, ...newStyle });
    };

    return (
        <>
            <ArrowBackButton
                onClick={() => changeView("DefaultToolbar")}
                title={selectI18nStringById("annotationMenuReturnText")}
                data-testid="highlight-back-button"
            />
            <Divider />
            {Object.keys(highlightColorStrings).map((color) => (
                <button
                    aria-label={selectI18nStringById(
                        highlightColorStrings[color]
                    )}
                    className={classes(
                        styles.highlightMenuColor,
                        style.color === color && styles.active
                    )}
                    key={color}
                    onClick={() => updateHighlightStyle({ color })}
                    style={{
                        backgroundColor: `var(--${color}Underline)`,
                    }}
                    data-testid={`${color}-highlight`}
                />
            ))}
            <Divider />
            <MarkOption
                aria-label={selectI18nStringById("highlightMarkHighlight")}
                className={classes(
                    !(style.underline || style.clear) && styles.active,
                    styles.markOptionHighlight
                )}
                color={style.color}
                onClick={() =>
                    updateHighlightStyle({ underline: false, clear: false })
                }
                data-testid="full-height-highlight"
            />
            <MarkOption
                aria-label={selectI18nStringById("highlightMarkUnderline")}
                className={style.underline && !style.clear && styles.active}
                color={style.color}
                onClick={() =>
                    updateHighlightStyle({ underline: true, clear: false })
                }
                underline
                data-testid="underline-highlight"
            />
            <MarkOption
                aria-label={selectI18nStringById("highlightMarkClear")}
                className={style.clear && styles.active}
                clear
                onClick={() => updateHighlightStyle({ clear: true })}
                data-testid="clear-highlight"
            />
        </>
    );
};

const HighlightMenuContainer = ({ ...props }) => {
    const dispatch = useDispatch();
    const activeAnnotation = useSelector(selectActiveHighlightAnnotation);
    const defaultStyle = useSelector(selectDefaultStyle);
    const selectI18nStringById = useSelectI18nStringById();

    return (
        <HighlightMenu
            {...props}
            annotation={activeAnnotation}
            defaultStyle={defaultStyle}
            selectI18nStringById={selectI18nStringById}
            updateHighlightStyle={(style) =>
                dispatch(updateHighlightStyle(style))
            }
        />
    );
};

export default HighlightMenuContainer;
